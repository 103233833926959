import {LOCATION_CHANGE} from 'react-router-redux';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import {call, fork, put, select, take, takeEvery, takeLatest} from 'redux-saga/effects';

import {actions as globalActions} from 'config/redux/global';
import {RoadPanelService} from 'services/RoadPanelService';
import {clipGeoJSON} from 'utils/map/clipRoads';

import {actions} from './actions';

import type {Action} from 'redux-act';
import type {RootState} from 'config/redux/rootReducer';
import type {RoadPanel} from 'models/RoadPanel';
interface IPanelProps {
  id: string;
  location: {
    center: Pick<GeoJSON.Point, 'coordinates'>;
  };
}

function* handleOpenRoad() {
  const panel: RootState['panelForm']['panel'] = yield select<RootState>(
    ({panelForm}) => panelForm.panel,
  );

  try {
    const service = RoadPanelService.getInstance();
    const resp: {raw: RoadPanel; curated: RoadPanel} = yield service.getData(panel);
    const {raw: rawData, curated: roadData} = resp;

    if (isEmpty(rawData)) {
      yield put(globalActions.showMessage(`Couldn't open road data from ${panel.id} panel`));
      yield put(actions.closeCurationRoad());
    } else {
      yield put(actions.setRawRoad(rawData));

      const actionResult = isEmpty(roadData)
        ? actions.setCurationRoad(getClipData(panel, rawData))
        : actions.initCurationRoad(roadData);

      yield put(actionResult);
    }
  } catch (err) {
    yield put(globalActions.showMessage(`Couldn't open road data from ${panel.id} panel`));
    yield put(actions.closeCurationRoad());
  }
}

function* handleResetRoad() {
  const {panel, rawRoadPanel, roadPanel}: RootState['panelForm'] = yield select<RootState>(
    ({panelForm}) => panelForm,
  );

  const result = {
    ...getClipData(panel, rawRoadPanel),
    updatedAt: roadPanel.updatedAt,
  };

  yield put(actions.setCurationRoad(result));
}

function* handleUndoRoad() {
  const iniRoad: RootState['panelForm']['initialRoadPanel'] = yield select<RootState>(
    ({panelForm}) => panelForm.initialRoadPanel,
  );

  yield put(actions.initCurationRoad(iniRoad));
}

function getClipData(panel: IPanelProps, rawData: RoadPanel): RoadPanel {
  const [lon, lat] = panel.location.center.coordinates;
  const features = rawData.data.features.map((item) =>
    item.geometry.type === 'MultiPolygon'
      ? ({
          ...item,
          geometry: {coordinates: item.geometry.coordinates[0], type: 'Polygon'},
        } as typeof item)
      : item,
  );

  return {
    id: panel.id,
    data: clipGeoJSON(features),
    center: {lat, lon},
    updatedAt: '',
  };
}

function* handleSaveRoad() {
  type PanelForm = RootState['panelForm'];
  const {roadPanel, panel}: PanelForm = yield select<RootState>(({panelForm}) => panelForm);
  const service = RoadPanelService.getInstance();
  const {id} = panel;

  try {
    yield put(globalActions.showLoading());
    yield put(globalActions.showMessage('Saving Road ...'));

    if (roadPanel.updatedAt) {
      yield service.updateCurated({id, ...roadPanel});
    } else {
      yield service.createCurated({id, ...roadPanel});
    }

    yield call(checkViewshedChange, {panel, roadPanel});

    yield put(globalActions.showMessage('Road saved'));
  } catch (err) {
    const {message = 'Failed to save Road'} = err.response.data;
    yield put(globalActions.showMessage(message));
  } finally {
    yield put(globalActions.hideLoading());
  }
}

function* checkViewshedChange({
  panel,
  roadPanel,
}: Pick<RootState['panelForm'], 'panel' | 'roadPanel'>) {
  const {geometry} = roadPanel.data.features.find(({properties: p}) => p.type === 'viewshed');
  const {coordinates: viewshedRoad} = geometry as GeoJSON.MultiPolygon;

  if (!isEqual(viewshedRoad, panel.location.viewshed.coordinates)) {
    const {location} = panel;
    const {bearing, center} = location;
    const {type, coordinates} = geometry as GeoJSON.MultiPolygon;

    yield put(
      actions.updatePanel({
        id: panel.id,
        location: {
          bearing,
          center,
          viewshed: {type, coordinates},
        },
      }),
    );
    yield put(globalActions.showMessage('Saving panel Viewshed'));
  }
}

function* handleChangeRoute() {
  while (yield take(actions.openCurationRoad)) {
    yield take(LOCATION_CHANGE);
    yield put(actions.closeCurationRoad());
  }
}

function* handleSetAlert({payload: id}: Action<number>) {
  yield put(actions.setAlertRoadPoint(id));
}

function* handleRemoveAlert({payload: id}: Action<number>) {
  yield put(actions.removeAlertRoadPoint(id));
}

export function* roadsFlow() {
  yield fork(handleChangeRoute);

  yield takeEvery(actions.openCurationRoad, handleOpenRoad);
  yield takeEvery(actions.undoCurationRoad, handleUndoRoad);
  yield takeEvery(actions.resetCurationRoad, handleResetRoad);
  yield takeEvery(actions.saveCurationRoad, handleSaveRoad);
  yield takeLatest(actions.trySetAlertRoadPoint, handleSetAlert);
  yield takeLatest(actions.tryRemoveAlertRoadPoint, handleRemoveAlert);
}
