/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Assets sorting options.
 */
export enum AssetSortOption {
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  CURATION_STATUS_ASC = "CURATION_STATUS_ASC",
  CURATION_STATUS_DESC = "CURATION_STATUS_DESC",
  DATA_ORIGIN_ASC = "DATA_ORIGIN_ASC",
  DATA_ORIGIN_DESC = "DATA_ORIGIN_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  LOCATION_DESCRIPTION_ASC = "LOCATION_DESCRIPTION_ASC",
  LOCATION_DESCRIPTION_DESC = "LOCATION_DESCRIPTION_DESC",
  MARKET_NAME_ASC = "MARKET_NAME_ASC",
  MARKET_NAME_DESC = "MARKET_NAME_DESC",
  MEDIA_NAME_ASC = "MEDIA_NAME_ASC",
  MEDIA_NAME_DESC = "MEDIA_NAME_DESC",
  PANEL_STATUS_ASC = "PANEL_STATUS_ASC",
  PANEL_STATUS_DESC = "PANEL_STATUS_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
  UPDATED_BY_ASC = "UPDATED_BY_ASC",
  UPDATED_BY_DESC = "UPDATED_BY_DESC",
}

export enum AudienceDatasetProvider {
  ACXIOM = "ACXIOM",
  CLARITAS = "CLARITAS",
  EPSILON = "EPSILON",
}

export enum CurationStatus {
  COMPLETED = "COMPLETED",
  DRAFT = "DRAFT",
  HOLD = "HOLD",
  INACTIVE = "INACTIVE",
  NOT_FOUND = "NOT_FOUND",
  PUBLISHED = "PUBLISHED",
  REVIEW = "REVIEW",
  UNAVAILABLE = "UNAVAILABLE",
}

export enum DataOrigin {
  IMS = "IMS",
  MAD = "MAD",
}

export enum DistanceUnit {
  FEET = "FEET",
  MILES = "MILES",
}

export enum Format {
  DIGITAL = "DIGITAL",
  STATIC = "STATIC",
}

export enum IlluminationPeriod {
  EIGHTEEN_HOURS = "EIGHTEEN_HOURS",
  TWELVE_HOURS = "TWELVE_HOURS",
  TWENTY_FOUR_HOURS = "TWENTY_FOUR_HOURS",
}

export enum LocationType {
  BLOCK_GROUP = "BLOCK_GROUP",
  CBSA = "CBSA",
  CENSUS_TRACT = "CENSUS_TRACT",
  COUNTY = "COUNTY",
  CSA = "CSA",
  DMA = "DMA",
  NEIGHBORHOOD = "NEIGHBORHOOD",
  PLACE = "PLACE",
  STATE = "STATE",
  ZIP_CODE = "ZIP_CODE",
}

/**
 * Market sorting options.
 */
export enum MarketsSortOption {
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

/**
 * Media Type field options.
 */
export enum MediaTypeField {
  MEDIA_NAME = "MEDIA_NAME",
  MEDIA_NAME_SYNONYMS = "MEDIA_NAME_SYNONYMS",
}

export enum Orientation {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

/**
 * Type of curation activity performed.
 */
export enum PanelCurationEventType {
  CREATE = "CREATE",
  DELETE = "DELETE",
  UPDATE = "UPDATE",
}

/**
 * Panels sorting options.
 */
export enum PanelSortOption {
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  CURATION_STATUS_ASC = "CURATION_STATUS_ASC",
  CURATION_STATUS_DESC = "CURATION_STATUS_DESC",
  DATA_ORIGIN_ASC = "DATA_ORIGIN_ASC",
  DATA_ORIGIN_DESC = "DATA_ORIGIN_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  LOCATION_DESCRIPTION_ASC = "LOCATION_DESCRIPTION_ASC",
  LOCATION_DESCRIPTION_DESC = "LOCATION_DESCRIPTION_DESC",
  MARKET_NAME_ASC = "MARKET_NAME_ASC",
  MARKET_NAME_DESC = "MARKET_NAME_DESC",
  MEDIA_NAME_ASC = "MEDIA_NAME_ASC",
  MEDIA_NAME_DESC = "MEDIA_NAME_DESC",
  PANEL_STATUS_ASC = "PANEL_STATUS_ASC",
  PANEL_STATUS_DESC = "PANEL_STATUS_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
  UPDATED_BY_ASC = "UPDATED_BY_ASC",
  UPDATED_BY_DESC = "UPDATED_BY_DESC",
}

export enum PanelStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  MISSING = "MISSING",
  UNAVAILABLE = "UNAVAILABLE",
}

export enum PanelVisibility {
  EXTERIOR = "EXTERIOR",
  INDOOR = "INDOOR",
  ROADSIDE = "ROADSIDE",
}

/**
 * Sort options for curation events search results.
 */
export enum PanelsCurationEventsSort {
  PUBLISHED_ASC = "PUBLISHED_ASC",
  PUBLISHED_DESC = "PUBLISHED_DESC",
}

export enum ProductRateType {
  PANEL = "PANEL",
  SHOWINGS = "SHOWINGS",
}

/**
 * The operator to be used with a Lucene query. AND or OR.
 */
export enum QueryOperator {
  AND = "AND",
  OR = "OR",
}

export enum QuintileDataSetStatus {
  ARCHIVED = "ARCHIVED",
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
}

/**
 * quintiles sorting options.
 */
export enum QuintileSortOption {
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  MARKET_CODE_ASC = "MARKET_CODE_ASC",
  MARKET_CODE_DESC = "MARKET_CODE_DESC",
  MARKET_DEFINITION_ASC = "MARKET_DEFINITION_ASC",
  MARKET_DEFINITION_DESC = "MARKET_DEFINITION_DESC",
  MARKET_NAME_ASC = "MARKET_NAME_ASC",
  MARKET_NAME_DESC = "MARKET_NAME_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  SCORE = "SCORE",
  STATUS_ASC = "STATUS_ASC",
  STATUS_DESC = "STATUS_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

/**
 * Road Segment source options.
 */
export enum RoadSegmentSource {
  CLIPPED = "CLIPPED",
  DEFAULT = "DEFAULT",
  MAD_CURATED = "MAD_CURATED",
  RAW = "RAW",
  TRUFACTOR = "TRUFACTOR",
}

/**
 * Showing curation status options.
 */
export enum ShowingCurationStatus {
  DRAFT = "DRAFT",
  INACTIVE = "INACTIVE",
  PUBLISHED = "PUBLISHED",
}

/**
 * Showing data origin options.
 */
export enum ShowingDataOrigin {
  IMS = "IMS",
  MAD = "MAD",
}

/**
 * Showing status options.
 */
export enum ShowingStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

/**
 * Showings sorting options.
 */
export enum ShowingsSortOption {
  COUNT_STATION_ASC = "COUNT_STATION_ASC",
  COUNT_STATION_DESC = "COUNT_STATION_DESC",
  COVERAGE_ASC = "COVERAGE_ASC",
  COVERAGE_DESC = "COVERAGE_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  CURATION_STATUS_ASC = "CURATION_STATUS_ASC",
  CURATION_STATUS_DESC = "CURATION_STATUS_DESC",
  DATA_ORIGIN_ASC = "DATA_ORIGIN_ASC",
  DATA_ORIGIN_DESC = "DATA_ORIGIN_DESC",
  DESCRIPTION_ASC = "DESCRIPTION_ASC",
  DESCRIPTION_DESC = "DESCRIPTION_DESC",
  MARKET_CODE_ASC = "MARKET_CODE_ASC",
  MARKET_CODE_DESC = "MARKET_CODE_DESC",
  MARKET_NAME_ASC = "MARKET_NAME_ASC",
  MARKET_NAME_DESC = "MARKET_NAME_DESC",
  MEDIA_NAME_ASC = "MEDIA_NAME_ASC",
  MEDIA_NAME_DESC = "MEDIA_NAME_DESC",
  MEDIA_NAME_IMPRESSIONS_ASC = "MEDIA_NAME_IMPRESSIONS_ASC",
  MEDIA_NAME_IMPRESSIONS_DESC = "MEDIA_NAME_IMPRESSIONS_DESC",
  NAME_ON_CONTRACT_ASC = "NAME_ON_CONTRACT_ASC",
  NAME_ON_CONTRACT_DESC = "NAME_ON_CONTRACT_DESC",
  PACKAGE_ASC = "PACKAGE_ASC",
  PACKAGE_DESC = "PACKAGE_DESC",
  RATE_ASC = "RATE_ASC",
  RATE_DESC = "RATE_DESC",
  SCORE = "SCORE",
  SHORT_ID_ASC = "SHORT_ID_ASC",
  SHORT_ID_DESC = "SHORT_ID_DESC",
  SIZE1_ASC = "SIZE1_ASC",
  SIZE1_DESC = "SIZE1_DESC",
  SIZE2_ASC = "SIZE2_ASC",
  SIZE2_DESC = "SIZE2_DESC",
  SIZE_ASC = "SIZE_ASC",
  SIZE_DESC = "SIZE_DESC",
  STATUS_ASC = "STATUS_ASC",
  STATUS_DESC = "STATUS_DESC",
  UNITS_ASC = "UNITS_ASC",
  UNITS_DESC = "UNITS_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

export enum StationAggregationFieldOption {
  AGENCY_NAME = "AGENCY_NAME",
  CONNECTING_STATIONS = "CONNECTING_STATIONS",
  PLARFORMS = "PLARFORMS",
  ROUTES = "ROUTES",
  SERVICES = "SERVICES",
  STATION_ID = "STATION_ID",
}

/**
 * Station sorting options.
 */
export enum StationSortOption {
  AGENCY_CODE_ASC = "AGENCY_CODE_ASC",
  AGENCY_CODE_DESC = "AGENCY_CODE_DESC",
  AGENCY_NAME_ASC = "AGENCY_NAME_ASC",
  AGENCY_NAME_DESC = "AGENCY_NAME_DESC",
  CONNECTING_STATIONS_ASC = "CONNECTING_STATIONS_ASC",
  CONNECTING_STATIONS_DESC = "CONNECTING_STATIONS_DESC",
  COUNT_SHOWINGS_ASC = "COUNT_SHOWINGS_ASC",
  COUNT_SHOWINGS_DESC = "COUNT_SHOWINGS_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  LOCATION_NEIGHBORHOOD_ASC = "LOCATION_NEIGHBORHOOD_ASC",
  LOCATION_NEIGHBORHOOD_DESC = "LOCATION_NEIGHBORHOOD_DESC",
  LOCATION_PLACE_ASC = "LOCATION_PLACE_ASC",
  LOCATION_PLACE_DESC = "LOCATION_PLACE_DESC",
  MARKETS_CODE_ASC = "MARKETS_CODE_ASC",
  MARKETS_CODE_DESC = "MARKETS_CODE_DESC",
  MARKETS_NAME_ASC = "MARKETS_NAME_ASC",
  MARKETS_NAME_DESC = "MARKETS_NAME_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  PLATFORMS_ASC = "PLATFORMS_ASC",
  PLATFORMS_DESC = "PLATFORMS_DESC",
  ROUTES_ASC = "ROUTES_ASC",
  ROUTES_DESC = "ROUTES_DESC",
  SERVICES_ASC = "SERVICES_ASC",
  SERVICES_DESC = "SERVICES_DESC",
  STATION_ID_ASC = "STATION_ID_ASC",
  STATION_ID_DESC = "STATION_ID_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

/**
 * Authorization system roles for users.
 */
export enum SystemRole {
  ADMIN = "ADMIN",
  ALL = "ALL",
  DMP_EDITOR = "DMP_EDITOR",
  DMP_GUEST = "DMP_GUEST",
  MAD_CURATOR = "MAD_CURATOR",
  MAD_EDITOR = "MAD_EDITOR",
  MAD_MARKET_OWNER = "MAD_MARKET_OWNER",
  MAD_PUBLISHER = "MAD_PUBLISHER",
  PAD_EDITOR = "PAD_EDITOR",
}

export interface AudienceKeywordAutocompleteInput {
  input: string;
  weight: number;
}

export interface AudienceKeywordDefinitionCreateInput {
  category: string;
  description: string;
  name: string;
  type: string;
}

export interface AudienceKeywordDefinitionUpdateInput {
  autoComplete?: AudienceKeywordAutocompleteInput[] | null;
  category: string;
  description: string;
  hits?: number | null;
  keywords?: string | null;
  lastUsed?: string | null;
  longDesc?: string | null;
  name: string;
  queryDef: string;
  type: string;
}

export interface ContainsIDInputFilter {
  _in?: string[] | null;
  _nin?: string[] | null;
}

export interface ContainsShowingsMarketInputFilter {
  _in?: ShowingsPartialMarketInput[] | null;
  _nin?: ShowingsPartialMarketInput[] | null;
}

export interface FullTextSearchInputFilter {
  _value: string;
}

export interface IntInputFilters {
  _eq?: number | null;
  _exists?: boolean | null;
  _gt?: number | null;
  _gte?: number | null;
  _in?: number[] | null;
  _lt?: number | null;
  _lte?: number | null;
  _neq?: number | null;
  _nin?: number[] | null;
}

/**
 * New panel input data
 */
export interface PanelCreateInput {
  curationNotes?: string | null;
  curationStatus: CurationStatus;
  facing?: string | null;
  format: Format;
  gtReferenceNumber?: string | null;
  inventoryNumber: string;
  location: PanelLocationCreateInput;
  locationDescription: string;
  marketCode: string;
  marketingImageAcceptable?: boolean | null;
  marketingImageUrl?: string | null;
  mediaName: string;
  panelStatus: PanelStatus;
  prime?: boolean | null;
  visibility?: PanelVisibility | null;
}

/**
 * Curated asset data.
 */
export interface PanelCurationEventObjectInput {
  id: string;
  name?: string | null;
  type: string;
  url: string;
}

/**
 * New location input data
 */
export interface PanelLocationCreateInput {
  bearing?: number | null;
  center: PointCreateInput;
  crossStreet?: string | null;
  primaryStreet?: string | null;
  viewshed?: PolygonCreateInput | null;
}

/**
 * Update panel data.
 */
export interface PanelUpdateInput {
  curatedRoadSegments?: boolean | null;
  curationNotes?: string | null;
  curationStatus?: CurationStatus | null;
  facing?: string | null;
  gtReferenceNumber?: string | null;
  location?: PanelLocationCreateInput | null;
  marketingImageAcceptable?: boolean | null;
  marketingImageUrl?: string | null;
  mediaCategory?: string | null;
  mediaNameSynonyms?: string[] | null;
  mediaSubCategory?: string | null;
  prime?: boolean | null;
  visibility?: PanelVisibility | null;
}

/**
 * New Point input data
 */
export interface PointCreateInput {
  coordinates: number[];
  type: string;
}

/**
 * New Polygon input data
 */
export interface PolygonCreateInput {
  coordinates: number[][][];
  type: string;
}

export interface ProductCycleInput {
  end: any;
  start: any;
}

export interface ProductRatesInput {
  cycle?: ProductCycleInput | null;
  id: string;
  type: ProductRateType;
}

export interface QuintileStepInput {
  id: string;
  legend: QuintilesLegendInput;
}

export interface QuintileUpdateInput {
  marketCode?: string | null;
  marketDefinition?: string | null;
  marketName?: string | null;
  name?: string | null;
  source?: string | null;
  status?: QuintileDataSetStatus | null;
  steps?: QuintileStepInput[] | null;
}

export interface QuintilesLegendInput {
  from?: string | null;
  to?: string | null;
}

/**
 * Update RoadSegment input data
 */
export interface RoadSegmentsInput {
  data: any;
  source: RoadSegmentSource;
}

export interface ShowingsCurationStatusInputFilter {
  _eq?: ShowingCurationStatus | null;
  _in?: ShowingCurationStatus[] | null;
  _neq?: ShowingCurationStatus | null;
  _nin?: ShowingCurationStatus[] | null;
}

export interface ShowingsDataOriginInputFilter {
  _eq?: ShowingDataOrigin | null;
  _in?: ShowingDataOrigin[] | null;
  _neq?: ShowingDataOrigin | null;
  _nin?: ShowingDataOrigin[] | null;
}

export interface ShowingsFilterContextInput {
  _id?: ContainsIDInputFilter | null;
  _not?: ShowingsFilterInput | null;
  _or?: ShowingsFilterInput[] | null;
  _search?: FullTextSearchInputFilter | null;
  coverage?: StringInputFilters | null;
  curationStatus?: ShowingsCurationStatusInputFilter | null;
  dataOrigin?: ShowingsDataOriginInputFilter | null;
  description?: StringInputFilters | null;
  marketCode?: StringInputFilters | null;
  marketName?: StringInputFilters | null;
  markets?: ContainsShowingsMarketInputFilter | null;
  mediaName?: StringInputFilters | null;
  mediaNameImpressions?: StringInputFilters | null;
  nameOnContract?: StringInputFilters | null;
  package?: StringInputFilters | null;
  shortId?: StringInputFilters | null;
  status?: ShowingsStatusInputFilter | null;
}

export interface ShowingsFilterInput {
  coverage?: StringInputFilters | null;
  curationStatus?: ShowingsCurationStatusInputFilter | null;
  dataOrigin?: ShowingsDataOriginInputFilter | null;
  description?: StringInputFilters | null;
  marketCode?: StringInputFilters | null;
  marketName?: StringInputFilters | null;
  markets?: ContainsShowingsMarketInputFilter | null;
  mediaName?: StringInputFilters | null;
  mediaNameImpressions?: StringInputFilters | null;
  nameOnContract?: StringInputFilters | null;
  package?: StringInputFilters | null;
  shortId?: StringInputFilters | null;
  status?: ShowingsStatusInputFilter | null;
}

export interface ShowingsPartialMarketInput {
  code?: string | null;
  name?: string | null;
}

export interface ShowingsStatusInputFilter {
  _eq?: ShowingStatus | null;
  _in?: ShowingStatus[] | null;
  _neq?: ShowingStatus | null;
  _nin?: ShowingStatus[] | null;
}

export interface StationFilterContextInput {
  _id?: ContainsIDInputFilter | null;
  _not?: StationInputFilter | null;
  _or?: StationInputFilter[] | null;
  _search?: FullTextSearchInputFilter | null;
  agencyCode?: StringInputFilters | null;
  agencyName?: StringInputFilters | null;
  connectingStations?: StringArrayInputFilters | null;
  location?: StationLocationInputFilter | null;
  markets?: StationMarketsInputFilter | null;
  meanDwellTime?: IntInputFilters | null;
  name?: StringInputFilters | null;
  platforms?: StringArrayInputFilters | null;
  routes?: StringArrayInputFilters | null;
  services?: StringArrayInputFilters | null;
  stationId?: StringInputFilters | null;
}

export interface StationInputFilter {
  agencyCode?: StringInputFilters | null;
  agencyName?: StringInputFilters | null;
  connectingStations?: StringArrayInputFilters | null;
  location?: StationLocationInputFilter | null;
  markets?: StationMarketsInputFilter | null;
  meanDwellTime?: IntInputFilters | null;
  name?: StringInputFilters | null;
  platforms?: StringArrayInputFilters | null;
  routes?: StringArrayInputFilters | null;
  services?: StringArrayInputFilters | null;
  stationId?: StringInputFilters | null;
}

export interface StationLocationInputFilter {
  neighborhood?: StringInputFilters | null;
  place?: StringInputFilters | null;
}

export interface StationMarketsInputFilter {
  code?: StringArrayInputFilters | null;
  name?: StringArrayInputFilters | null;
}

export interface StringArrayInputFilters {
  _endsWith?: string | null;
  _exists?: boolean | null;
  _ilike?: string | null;
  _in?: string[] | null;
  _like?: string | null;
  _nin?: string[] | null;
  _nlike?: string | null;
  _startWith?: string | null;
}

export interface StringInputFilters {
  _endsWith?: string | null;
  _eq?: string | null;
  _exists?: boolean | null;
  _ilike?: string | null;
  _in?: string[] | null;
  _like?: string | null;
  _neq?: string | null;
  _nin?: string[] | null;
  _nlike?: string | null;
  _startWith?: string | null;
}

export interface UploadInput {
  name: string;
  path?: string | null;
  rename?: string | null;
  type?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
